.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  display: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  align-items: center;
  z-index: 100;
}

.fullSize {
  height: 100%;
  width: 100%;
}

.fullSize .ui-provider {
  min-height: 100vh;
}

html,
body {
  height: 100%;
  margin: 0;
}

@media screen and (max-width: 500px) { 
  html,
  body {
    font-family: 'Graphik' !important;
  }
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f0eeee;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dashboardContainer {
  display: flex;
  gap: 20px;
  padding: 10px;
}

.dashboardContainer .dashboardItem {
  flex-basis: 100%;
  text-align: center;
  flex-shrink: 1;
}

.lightbgColor{
  background-color: #8080801a !important;
}

.MuiBadge-root{
  margin-left: 17px !important;
}

.tabContainer{
  box-shadow: 0px 3px 8px rgb(0 0 0 / 20%) !important;
}

.dark > p > img, .contrast > p > img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.MuiTab-textColorInherit.Mui-selected {
  text-transform: capitalize;
  text-align: center;
  font: normal normal 600 14px/18px Graphik;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.dark .MuiTab-textColorInherit.Mui-selected, .contrast .MuiTab-textColorInherit.Mui-selected {
  text-transform: capitalize;
  text-align: center;
  font: normal normal 600 14px/18px Graphik;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}

.dark .MuiTabs-flexContainer>button, .contrast .MuiTabs-flexContainer>button{
  color: white;
}

.MuiTabs-flexContainer>button {
  text-transform: capitalize;
  color: #222222;
  opacity: 1;
  font-family: 'Graphik' !important;
}

.privacyNoteDiv{
  display: flex;
  justify-content: end;
}

.Toastify__toast--success {
  background: #2B8461 0% 0% no-repeat padding-box  !important;
  box-shadow: 2px 2px 3px #00000029;
  border: 0.5px solid #149F69;
  opacity: 1;
  filter: blur(0px);
}

.Toastify__progress-bar {
  background-color: transparent !important;
}
.Toastify__toast--error{
  background: #B10B02 0% 0% no-repeat padding-box  !important;
  box-shadow: 2px 2px 3px #00000029;
  opacity: 1;
  filter: blur(0px);
}

.Toastify__toast {
  font-family: 'Graphik' !important;
}

.active-users-count {
  font: normal normal normal 14px/0px Graphik;
  letter-spacing: -0.35px;
  color: #000000
}

.active-users-label {
  font: normal normal 500 14px/0px Graphik;
  letter-Spacing: -0.35px;
  color: #000000
}

.dark.dashboard-footer {
  background-color: #2d2c2c !important;
  box-Shadow: "0px 3px 6px #00000029",
}

.dark .active-users-count {
  color: #ffffff !important;
}

.dark .active-users-label {
  color: #ffffff !important;
}

.contrast.dashboard-footer {
  background-color: #000000 !important;
  box-Shadow: "0px 3px 6px #00000029",
}

.contrast .active-users-count {
  color: #ffffff !important;
}

.contrast .active-users-label {
  color: #ffffff !important;
}

.NoDataFoundCommon {
font-weight: bold;
padding: 10px;
font: normal normal 650 14px Graphik !important;
}